import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { Logger } from '@app/@core/logger.service';
import { divisionRights, entityRights, Groups, marketplaceRights } from '../roles/groups.enum';
import { AppPathType, UserRoleType } from '@shared/models/context/app-context';
import { EntityRefService } from '@app/@services/entity-ref.service';
import { UserContextApiService } from '@app/@services/user-context/user-context.api.service';
import { AccountContextService } from '@app/@services/account/account-context.service';
import { UserContext, UserContextService } from '@app/@services/authentification/user-context.service';
import {
  giEntity,
  giMarketplacesNames,
  giUserMarketplaces,
  giUserRoles,
  initPathRoleType,
} from '@app/app.global.immutables';

const log = new Logger('EntityGuard');

const AllowedUsers = [
  Groups.super_admin,
  Groups.admin,
  Groups.gest_circuit,
  Groups.gest_circuit_junior,
  Groups.gest_modele,
  Groups.resp_metier,
  Groups.resp_suivi,
  Groups.destinataire,
];
const AllowedUsersDiv = [
  Groups.super_admin,
  Groups.admin_div,
  Groups.gest_circuit_div,
  Groups.gest_circuit_junior_div,
  Groups.gest_modele_div,
  Groups.resp_metier_div,
  Groups.resp_suivi_div,
  Groups.destinataire_div,
];

@Injectable({ providedIn: 'root' })
export class EntityGuard {
  private userContextApiService = inject(UserContextApiService);

  private entityRefService = inject(EntityRefService);

  private router = inject(Router);

  private userContextService = inject(UserContextService);

  private accountContextService = inject(AccountContextService);

  userContextSession: UserContext = {};

  getFromStoreOrAPI(entityName: string): Observable<any> {
    return this.userContextApiService
      .getEntity(entityName)
      .pipe(
        tap((res: any) => {
          const { reference, name, frontPath } = res;

          this.entityRefService.entityRef = reference;
          this.entityRefService.entityName = name;
          this.entityRefService.entityFrontPath = frontPath;
          this.userContextSession.entityRef = reference;
          this.userContextSession.entityName = name;
          this.userContextService.saveUserContext(this.userContextSession);
        }),
        switchMap(() => {
          if (giEntity?.addOns?.supportMarketplaces) {
            this.userContextApiService.getMarketplaceTree().subscribe();
          }

          if (giEntity.addOns.enableDivision) {
            this.userContextApiService.getDivisionTree().subscribe();
          }

          return this.userContextApiService.getAccount().pipe(
            map((_auth) => {
              return {
                roles: giUserRoles,
                mpCurrentAccount: giUserMarketplaces || [],
                availableMarketplaces: giMarketplacesNames,
              };
            }),
            filter((auth: any) => auth.roles?.length > 0),
            map((auth: any) => this.manageRedirection(auth, entityName)),
          );
        }),
      )
      .pipe(
        catchError((err) => {
          const { status } = err;

          if (status === 404) {
            this.router.navigate(['/help'], {
              queryParams: { message: "l'entité introuvable" },
            });
          }

          return EMPTY;
        }),
        take(1),
        finalize(() => log.debug('complete')),
      );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const entity = route.params.entity;

    return this.getFromStoreOrAPI(entity);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  manageRedirection({ roles, availableMarketplaces, mpCurrentAccount }, entityName: string) {
    this.publishContext(roles);

    if (roles.some((r) => AllowedUsers.includes(r))) {
      return true;
    } else if (mpCurrentAccount !== undefined && mpCurrentAccount.length !== 0) {
      const firstMarketPlace = mpCurrentAccount[0];

      this.router.navigateByUrl(`${entityName}/${firstMarketPlace}`);

      return false;
    } else {
      return !!(
        giEntity.addOns.enableDivision &&
        !giEntity.addOns.supportMarketplaces &&
        roles.some((r) => AllowedUsersDiv.includes(r))
      );
    }
  }

  publishContext(roles: string[]) {
    const appContext = {
      appPathType: AppPathType.ENTITY_PATH,
      userRoleType: undefined,
    };

    if (divisionRights.some((divRight) => roles.includes(divRight))) {
      appContext.userRoleType = UserRoleType.USER_DIVISION;
      this.accountContextService.isUserDivision = true;
      this.accountContextService.isUserMarketplace = false;
      this.accountContextService.isUserEntity = false;
    } else if (marketplaceRights.some((mpRight) => roles.includes(mpRight))) {
      appContext.userRoleType = UserRoleType.USER_MARKETPLACE;
      this.accountContextService.isUserDivision = false;
      this.accountContextService.isUserMarketplace = true;
      this.accountContextService.isUserEntity = false;
    } else if (entityRights.some((entityRight) => roles.includes(entityRight))) {
      appContext.userRoleType = UserRoleType.USER_ENTITY;
      this.accountContextService.isUserDivision = false;
      this.accountContextService.isUserMarketplace = false;
      this.accountContextService.isUserEntity = true;
    }

    initPathRoleType(appContext.appPathType, appContext.userRoleType);
  }
}
