export class UserUtilsHelper {
  static userProfiles(userGroups: string[]): string[] {
    const groups = [];

    if (
      userGroups.indexOf('admin') > -1 ||
      userGroups.indexOf('admin_mp') > -1 ||
      userGroups.indexOf('admin_div') > -1
    ) {
      groups.push('admin');
    }

    if (
      userGroups.indexOf('resp_metier') > -1 ||
      userGroups.indexOf('resp_metier_mp') > -1 ||
      userGroups.indexOf('resp_metier_div') > -1
    ) {
      groups.push('resp_metier');
    }

    if (
      userGroups.indexOf('gest_circuit') > -1 ||
      userGroups.indexOf('gest_circuit_mp') > -1 ||
      userGroups.indexOf('gest_circuit_div') > -1
    ) {
      groups.push('gest_circuit');
    }

    if (
      userGroups.indexOf('gest_modele') > -1 ||
      userGroups.indexOf('gest_modele_mp') > -1 ||
      userGroups.indexOf('gest_modele_div') > -1
    ) {
      groups.push('gest_modele');
    }

    if (
      userGroups.indexOf('resp_suivi') > -1 ||
      userGroups.indexOf('resp_suivi_mp') > -1 ||
      userGroups.indexOf('resp_suivi_div') > -1
    ) {
      groups.push('resp_suivi');
    }

    if (
      userGroups.indexOf('destinataire') > -1 ||
      userGroups.indexOf('destinataire_mp') > -1 ||
      userGroups.indexOf('destinataire_div') > -1
    ) {
      groups.push('destinataire');
    }

    return groups;
  }
}
