import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import {
  giEntity,
  giMarketplaceTree,
  initAccount,
  initAreMarketplacesLoaded,
  initCurrentGroupName,
  initEntity,
  initGroups,
} from '@app/app.global.immutables';
import { DataService, MpDataType } from '@app/@services/data/data.service';
import { UserMapperService } from '../users/user-mapper.service';

const parentRef = 'SELV2';

// ⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠⚠ IMPORTANT ⚠⚠⚠⚠⚠⚠⚠
// NOTE:
// this service is special because it give us all infos about user (user info,entity;roles...)
// normaly the result of this apis may stocked in localStorage or sessionStorage
// but for security reasons it's adequate to make  calls in needs
// TO PREVENT multiple calls we create mechanisme for caching those api 🎉🎉
// but the question here is where this cache get new upadate? 🧐🧐...
// the answer is simple : in each RELOAD OF BROWSER
// we should mention when user make a logout  a reload occure after clean
// the token form localstorage
// so be careful with logout behaviour 😉
// NOTE:
// if this api used in EFFECT store : we should used with exhaustMap rxjs operator

@Injectable({
  providedIn: 'root',
})
export class UserContextApiService {
  private http = inject(HttpClient);

  private userMapperService = inject(UserMapperService);

  private dataService = inject(DataService);

  private marketplaceTree$: Observable<any>;

  private divisionTree$: Observable<any>;

  private account$: Observable<any>;

  private entity$: Observable<any>;

  private combinedEntityMp$: Observable<any>;

  getGroupTree(): Observable<any> {
    const pathMp = '/v3/group/_tree/marketplace?features=children,data';
    const pathDiv = '/v3/group/_tree/division';

    return this.http.get(pathMp).pipe(
      mergeMap((mp) => {
        if (Object.keys(mp).length > 0) {
          return of(mp);
        }

        return this.http.get(pathDiv);
      }),
      tap((tree) => {
        initGroups(tree);
        initAreMarketplacesLoaded(true);
      }),
      shareReplay(1)
    );
  }

  getMarketplaceTree() {
    const path = '/v3/group/_tree/marketplace?features=children,data';

    return (this.marketplaceTree$ = this.marketplaceTree$
      ? this.marketplaceTree$
      : this.http.get(path).pipe(
          tap((marketplaceTree) => {
            initGroups(marketplaceTree);
            initAreMarketplacesLoaded(true);
          }),
          shareReplay(1)
        ));
  }

  getDivisionTree() {
    const path = '/v3/group/_tree/division';

    return (this.divisionTree$ = this.divisionTree$
      ? this.divisionTree$
      : this.http.get(path).pipe(
          tap((divTree) => {
            initGroups(divTree);
          }),
          shareReplay(1)
        ));
  }

  getAccount() {
    const path =
      '/v3/user/current?features=profiles' + (giEntity?.addOns?.supportMarketplaces ? ',groups.children' : ',groups');

    return (this.account$ = this.account$
      ? this.account$
      : this.http.get(path).pipe(
          tap((account) => {
            initAccount(this.userMapperService.backendToFrontUser(account));
          }),
          shareReplay(1)
        ));
  }

  getEntity(entityName: string) {
    const path = '/internal/entities';

    return (this.entity$ = this.entity$
      ? this.entity$
      : this.http.get(`${path}/front/${entityName}?parentRef=${parentRef}`).pipe(
          tap((entity) => {
            initEntity(entity);
          }),
          shareReplay(1)
        ));
  }

  combineEntityWithMP(entityName?: string, mp?: string) {
    return (this.combinedEntityMp$ = this.combinedEntityMp$
      ? this.combinedEntityMp$
      : combineLatest([this.getEntity(entityName)]).pipe(
          shareReplay(1),
          map(([entity]) => {
            if (giMarketplaceTree === null) {
              return entity;
            }

            initCurrentGroupName(mp);

            const matchingMp = giMarketplaceTree.find(
              (data) => (data.name === mp || data.label === mp) && data.type === 'marketplace'
            );
            const addOnsMp = matchingMp ? matchingMp?.dataByKey[`info|marketplace|${mp}`]?.addOns : null;

            const mergedAddons = { ...entity.addOns, ...addOnsMp };

            return { ...entity, addOns: mergedAddons };
          }),
          tap((entity) => {
            initEntity(entity);
          })
        ));
  }

  combineEntityWithMPOfWorkflow(entityName?: string, mp?: string) {
    return combineLatest([this.dataService.getMarketplaceData(MpDataType.INFO, mp), this.getEntity(entityName)]).pipe(
      map(([mpOfWorkflow, entity]) => {
        if (mpOfWorkflow === null) {
          return entity;
        }

        const mergedAddons = { ...entity.addOns, ...mpOfWorkflow.addOns };

        return { ...entity, addOns: mergedAddons };
      })
    );
  }
}
