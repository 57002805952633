import { AfterViewChecked, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@env/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CredentialsService } from '@app/@services/authentification/credentials.service';
import { Logger } from '@core';
import { EntityAddons } from '@shared/models/entity/entity-addons';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './@shared/footer/footer.component';

import { SpinnerComponent } from './docapost-design-system/components/spinner/spinner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [SpinnerComponent, RouterOutlet, FooterComponent],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  private credential = inject(CredentialsService);

  private swUpdate = inject(SwUpdate);

  private modalService = inject(NgbModal);

  private router = inject(Router);

  pageLoaded = false;

  subscribtion: Subscription;

  entityAddons: EntityAddons;

  ngAfterViewChecked(): void {
    this.subscribtion = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => document.body.scrollTo(0, 0));
  }

  ngOnInit() {
    // service werker
    this.serviceWorkerInit();

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    this.credential.populate();
  }

  serviceWorkerInit() {
    // service worker check if it's supported in current browser!
    if (this.swUpdate.isEnabled) {
      // notify by  new version of the application
      this.swUpdate.versionUpdates.subscribe(() => {
        // reload if user comfirme using the new version
        this.modalService
          .open(ConfirmModal, {
            windowClass: 'modal-z-index warn',
            centered: true,
            backdropClass: 'backdrop-z-index',
            backdrop: 'static',
            keyboard: false,
          })
          .result.then((r) => {
            if (r === 'Accepter') {
              window.location.reload();
            }
          });
      });
    }
  }

  outletActivate() {
    this.pageLoaded = true;
  }

  ngOnDestroy(): void {
    this.subscribtion.unsubscribe();
  }
}

@Component({
  selector: 'app-ngbd-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ 'GENERAL.NEW_VERSION' | translate }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ 'LOAD_NEW_VERSION' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-ghost" (click)="activeModal.close('Accepter')">
        {{ 'GENERAL.ACCEPT' | translate }}
      </button>
      <button type="button" class="btn btn-sm btn-danger" (click)="activeModal.close('Annuler')">
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
    </div>
  `,
  standalone: true,
  imports: [TranslateModule],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ConfirmModal {
  activeModal = inject(NgbActiveModal);
}
