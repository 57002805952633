import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { EntityRefService } from '../../@services/entity-ref.service';
import { UserContextApiService } from '../../@services/user-context/user-context.api.service';
import { I18nService } from '@app/@i18n/i18n.service';

@Injectable({ providedIn: 'root' })
export class EntityResolver {
  private entityRefService = inject(EntityRefService);

  private userContextApiService = inject(UserContextApiService);

  private router = inject(Router);

  private i18nService = inject(I18nService);

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const entity = route.parent.paramMap.get('entity');

    return this.userContextApiService.getEntity(entity).pipe(
      tap((res: any) => {
        this.entityRefService.entityAddOns = res.addOns;
        this.i18nService.init('fr', res.addOns?.availableLocales);
        const { reference, name, frontPath } = res;

        this.entityRefService.entityRef = reference;
        this.entityRefService.entityName = name;
        this.entityRefService.entityFrontPath = frontPath;
      }),
      catchError((err) => {
        const { status } = err;

        if (status === 404) {
          this.router.navigate(['/help'], {
            queryParams: { message: "l'entité introuvable" },
          });
        }

        return EMPTY;
      }),
      take(1)
    );
  }
}
