@if (accessToFooter) {
  <footer class="footer row h-100 pt-1 pb-1 pl-5 pr-5 m-0">
    <div class="col-md-10 d-flex flex-md-row align-items-center flex-sm-column m-sm-3 m-md-0">
      <div *appNotLoggedIn>
        <app-menu #languageTemplate>
          @for (lang of supportedLangs; track lang) {
            <div class="d-flex justify-content-start align-items-center cursor-pointer">
              <a
                class="btn btn-sm btn-ghost-ghost"
                style="width: 100% !important; padding: 0.5rem 0.5rem; display: flex"
                (click)="onLangChange(lang); MenuTrigger.closeMenu()"
              >
                <img
                  style="margin-right: 1rem"
                  src="assets/images/flags/{{ lang.flag }}.png"
                  alt="{{ 'RGAA.LOGIN.FLAG_' + (lang.langId | uppercase) + '_ALT' | translate }}"
                />
                <span>{{ lang.label }}</span>
              </a>
            </div>
          }
        </app-menu>
        <div [appMenuTrigger]="languageTemplate" #MenuTrigger="MenuTrigger" class="ico-dropdown">
          <img
            src="assets/images/flags/{{ selectedLang.flag }}.png"
            alt="{{ 'RGAA.LOGIN.FLAG_' + (selectedLang.langId | uppercase) + '_ALT' | translate }}"
          />
          <i class="icon-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <nav role="navigation">
        <a
          class="btn btn-sm p-0 ml-md-2 btn-ghost-ghost font-weight-bolder p-sm-1 m-md-0 m-sm-1 mt-sm-3"
          [routerLink]="drawPath('legal-notice')"
        >
          <span class="text-ultramarine font-weight-normal fs-xs pr-2 pl-2">
            {{ 'FOOTER.LEGAL_NOTICES' | translate }}</span
          >
        </a>
        <a
          class="btn btn-sm p-0 btn-ghost-ghost font-weight-bolder p-sm-1 m-sm-1 m-md-0"
          [routerLink]="drawPath('cgu')"
        >
          <span class="text-ultramarine font-weight-normal fs-xs pr-2 pl-2"> {{ 'FOOTER.CGU' | translate }}</span>
        </a>
      </nav>
      <span class="text-ultramarine font-weight-normal fs-xs pr-2 pl-2"> {{ 'V' + appVersion }}</span>
      <img
        class="d-md-none d-sm-bloc mt-2"
        src="assets/images/trust_doca_logo.png"
        alt="{{ 'RGAA.LOGIN.LOGO_TECH_TRUST_DOCAPOSTE_ALT' | translate }}"
      />
    </div>
    <div class="col-md-2 d-flex flex-row justify-content-end d-sm-none d-md-flex">
      <img src="assets/images/trust_doca_logo.png" alt="{{ 'RGAA.LOGIN.LOGO_TECH_TRUST_DOCAPOSTE_ALT' | translate }}" />
    </div>
  </footer>
}
