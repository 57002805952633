import { Injectable } from '@angular/core';
const TOKEN_KEY = 'jwtToken';

@Injectable({ providedIn: 'root' })
export class TokenService {
  getToken(): string {
    return window.sessionStorage[TOKEN_KEY];
  }

  saveToken(token: string) {
    window.sessionStorage[TOKEN_KEY] = token;
  }

  destroyToken() {
    window.sessionStorage.removeItem(TOKEN_KEY);
  }
}
