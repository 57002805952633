import { Directive, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { CredentialsService } from '@app/@services/authentification/credentials.service';
import * as $ from 'jquery';

@Directive({
  selector: '[appNotLoggedIn]',
  standalone: true,
})
export class NotLoggedInDirective implements OnInit {
  private templateRef = inject<TemplateRef<any>>(TemplateRef);

  private viewContainer = inject(ViewContainerRef);

  private userService = inject(CredentialsService);

  ngOnInit() {
    return this.userService.isAuthenticated.subscribe((isAuthenticated) => {
      if (isAuthenticated === undefined || !isAuthenticated) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        $('app-root').addClass('h-100');
      } else {
        this.viewContainer.clear();
        $('app-root').removeClass('h-100');
      }
    });
  }
}
