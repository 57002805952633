import { Injectable } from '@angular/core';

export interface UserContext {
  entityRef?: string;
  entityName?: string;
  currentMarketplace?: string;
}
const USER_CONTEXT = 'userContext';

@Injectable({ providedIn: 'root' })
export class UserContextService {
  getUserContext(): UserContext {
    return JSON.parse(window.sessionStorage[USER_CONTEXT]);
  }

  saveUserContext(userContext: UserContext) {
    window.sessionStorage[USER_CONTEXT] = JSON.stringify(userContext);
  }
}
