import { Component, inject } from '@angular/core';
import { LoaderService } from '@app/docapost-design-system/services/loader.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class SpinnerComponent {
  loader = inject(LoaderService);
}
