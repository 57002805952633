import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

export enum MpDataType {
  INFO = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private http = inject(HttpClient);

  getMarketplaceData(dataType: MpDataType, marketplaceName: string): Observable<any> {
    const dataQueryType = dataType.toString() + '%7Cmarketplace%7C' + marketplaceName + '/value';
    const path = '/v3/data/' + dataQueryType;

    return this.http.get(path).pipe(shareReplay(1));
  }
}
