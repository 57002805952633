/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode, LOCALE_ID, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import { AppComponent } from './app/app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app/app-routing.module';
import { QRCodeModule } from 'angularx-qrcode';
import { CoreModule } from '@core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressModule } from 'ngx-progressbar';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LocaleIdService } from '@app/@i18n/locale-id.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, esLocale, frLocale, itLocale, plLocale } from 'ngx-bootstrap/locale';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

if (environment.production) {
  enableProdMode();
}

defineLocale('fr', frLocale);
defineLocale('es', esLocale);
defineLocale('de', deLocale);
defineLocale('pl', plLocale);
defineLocale('it', itLocale);

const bootstrap = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        BrowserModule,
        BrowserAnimationsModule,
        NgProgressModule,
        NgProgressHttpModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
        CoreModule,
        QRCodeModule,
        AppRoutingModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
          enabled: environment.production,
        }),
        OverlayModule,
      ),
      { provide: LOCALE_ID, useClass: LocaleIdService },
      DatePipe,
      {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [TranslateService],
        multi: true,
      },
      provideHttpClient(withInterceptorsFromDi()),
    ],
  });

function appInitializerFactory(translate: TranslateService) {
  return () => {
    return firstValueFrom(translate.use('fr'));
    return translate.use('fr').toPromise();
  };
}

// eslint-disable-next-line no-console
bootstrap().catch(console.error);
