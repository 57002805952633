export interface AppContext {
  appPathType: AppPathType;
  userRoleType: UserRoleType;
  currentMarketplaceDataInfo: any;
  virtualUser: boolean;
}

export enum AppPathType {
  ENTITY_PATH = 'ENTITY_PATH',
  MARKETPLACE_PATH = 'MARKETPLACE_PATH',
}

export enum UserRoleType {
  USER_ENTITY = 'USER_ENTITY',
  USER_MARKETPLACE = 'USER_MARKETPLACE',
  USER_DIVISION = 'USER_DIVISION',
}
