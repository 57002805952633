import { Component, OnInit, inject } from '@angular/core';
import { NavigationService } from '@app/@services/navigation/navigation.service';
import { SessionPopupHelperService } from '@shared/session-expired/business/helpers/session-popup-helper.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-session-expired-popup',
  templateUrl: './session-expired-popup.component.html',
  styleUrls: ['./session-expired-popup.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class SessionExpiredPopupComponent implements OnInit {
  private navigationService = inject(NavigationService);

  private sessionPopupHelperService = inject(SessionPopupHelperService);

  ngOnInit(): void {}

  reconnect() {
    this.sessionPopupHelperService.closeModal();
    this.navigationService.navigate(['login']);
  }
}
