import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef,
  inject,
} from '@angular/core';
import {
  ConnectionPositionPair,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { POSITION_MAP } from './connection-position-pair';
import { merge, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { MenuComponent } from './menu.component';
import { Platform } from '@angular/cdk/platform';

enum MenuState {
  closed = 'closed',
  opened = 'opened',
}
@Directive({
  selector: '[appMenuTrigger]',
  exportAs: 'MenuTrigger',
  standalone: true,
})
export class MenuTriggerDirective implements OnDestroy, AfterViewInit {
  private el = inject(ElementRef);

  private overlay = inject(Overlay);

  private vcr = inject(ViewContainerRef);

  platform = inject(Platform);

  @Input() appMenuTrigger: MenuComponent;

  // TODO: add more flexibility for choosing the custom postion for floating compo
  @Input() MenuPosition;

  @Input() triggerBy: 'click' | 'hover' | null = 'click';

  menuState = MenuState.closed;

  private portal: TemplatePortal;

  private positions: ConnectionPositionPair[] = [
    POSITION_MAP.bottomLeft,
    POSITION_MAP.topLeft,
    POSITION_MAP.topRight,
    POSITION_MAP.bottomRight,
  ];

  private overlayRef: OverlayRef;

  private subscription = Subscription.EMPTY;

  private readonly hover$ = new Subject<boolean>();

  private readonly click$ = new Subject<boolean>();

  ngAfterViewInit(): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appMenuTrigger) {
      return;
    }

    this.click$.next(true);
  }

  @HostListener('window:scroll', ['$event'])
  scroll() {
    this.closeMenu();
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    this.hover$.next(true);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.hover$.next(false);
  }

  openMenu() {
    if (this.menuState === MenuState.opened) {
      return;
    }

    const overlayConfig = this.getOverlayConfig();

    this.setOverlayPosition(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);
    const overlayRef = this.overlay.create(overlayConfig);

    overlayRef.attach(this.getPortal());
    this.subscribeOverlayEvent(overlayRef);
    this.overlayRef = overlayRef;
    this.menuState = MenuState.opened;
  }

  closeMenu() {
    if (this.menuState === MenuState.opened) {
      this.overlayRef.detach();
      this.menuState = MenuState.closed;
    }
  }

  private initialize() {
    const menuVisible$ = this.appMenuTrigger.visible$;
    const hover$ = merge(menuVisible$, this.hover$).pipe(debounceTime(100));

    /*  ---true---false----
     *  ---------------true----false
     */
    const handle$ = this.triggerBy === 'hover' ? hover$ : this.click$;

    handle$.pipe(tap((state) => console.log(state))).subscribe((value) => {
      if (value) {
        this.openMenu();
        return;
      }

      this.closeMenu();
    });
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.isMobile()
      ? this.overlay.position().global().centerHorizontally().centerVertically()
      : this.overlay.position().flexibleConnectedTo(this.el).withPush(false);
    const backdropClass = this.isMobile() ? 'menu-backdrop-mobile' : 'menu-backdrop';

    return new OverlayConfig({
      positionStrategy,
      minWidth: '200px',
      hasBackdrop: this.triggerBy !== 'hover',
      backdropClass,
      panelClass: 'menu-panel',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private isMobile() {
    return this.platform.ANDROID || this.platform.IOS;
  }

  private setOverlayPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    if (this.isMobile()) return;
    if (this.MenuPosition) this.positions = [POSITION_MAP[this.MenuPosition]];
    positionStrategy.withPositions([...this.positions]);
  }

  private getPortal(): TemplatePortal {
    if (!this.portal || this.portal.templateRef !== this.appMenuTrigger.menuTemplate) {
      this.portal = new TemplatePortal<any>(this.appMenuTrigger.menuTemplate, this.vcr);
    }

    return this.portal;
  }

  private subscribeOverlayEvent(overlayRef: OverlayRef) {
    this.subscription.unsubscribe();
    this.subscription = merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      overlayRef.keydownEvents().pipe(filter((event) => event.keyCode === ESCAPE && !hasModifierKey(event))),
    ).subscribe(() => {
      this.closeMenu();
    });
  }
}
