import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Group } from '@shared';
import { Groups } from '@core/roles/groups.enum';
import { GuardsUtilsHelper } from '@core/helpers/guards-utils.helper';
import { defaultRoutes } from '@core/roles/default-routes';
import { giAccount } from '@app/app.global.immutables';

@Injectable({ providedIn: 'root' })
export class RoleGuard {
  private router = inject(Router);

  accountGroups: Group[];

  constructor() {
    this.accountGroups = giAccount.groups;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      !route.data.roles.includes(Groups.ALL) &&
      !this.accountGroups.some((group: Group) => route.data.roles.includes(group.name))
    ) {
      const rootPath = GuardsUtilsHelper.pathBuilder(route);
      const defaultGrpRoute = this.accountGroups.map((grp) => defaultRoutes[grp.name]).filter((v) => v !== undefined);

      if (defaultGrpRoute !== undefined && defaultGrpRoute.length > 0) {
        let defaultRoute = defaultGrpRoute[0];

        if (defaultGrpRoute.length > 1 && defaultGrpRoute[0].includes('contacts')) {
          defaultRoute = defaultGrpRoute[1];
        }

        this.router.navigate([rootPath].concat(defaultRoute));
      }

      return false;
    }

    return true;
  }
}
