import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loading = false;

  private message: string = null;

  setLoading(loading: boolean, message: string = null): void {
    this.loading = loading;
    this.setMessage(message);
  }

  getLoading(): boolean {
    return this.loading;
  }

  getMessage(): string {
    return this.message;
  }

  private setMessage(msg: string): void {
    this.message = msg;
  }
}
