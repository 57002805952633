<div class="modal-header">
  <h4 class="modal-title"><i class="icon-plus-circle"></i> {{ 'SESSION_EXPIRED_TITLE' | translate }}</h4>
</div>
<div class="modal-body">
  <p class="font-weight-normal text-ultramarine p-2">
    {{ 'SESSION_EXPIRED_MESSAGE' | translate }}
  </p>
</div>
<div class="modal-footer">
  <div class="float-right">
    <button class="btn btn-primary" (click)="reconnect()">
      {{ 'SESSION_EXPIRED_RECONNECT' | translate }}
    </button>
  </div>
</div>
