import { Injectable } from '@angular/core';
import { Group } from '@shared';

@Injectable({
  providedIn: 'root',
})
export class AccountContextService {
  isUserEntity: boolean;

  isUserMarketplace: boolean;

  isUserDivision: boolean;

  accountGroups: Group[];

  constructor() {}

  isEntityUser(): boolean {
    return this.isUserEntity;
  }

  isMarketPlaceUser(): boolean {
    return this.isUserMarketplace;
  }

  isDivisionUser(): boolean {
    return this.isUserDivision;
  }
}
