import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundResolver } from '@core/resolvers/notfound-resolver.service';

import { RedirectExternalProvider } from '@core/guards/redirect-external-guard.service';

const routes: Routes = [
  // we should keep this order for example if we want add another path {path:"xxx",...}
  // it should be above of :
  // {
  //   path: '',
  //   loadChildren: () =>
  //     import('@app/client/client.module').then((m) => m.ClientModule),
  // },
  {
    path: 'clients',
    children: [
      {
        path: '**',
        resolve: { profile: RedirectExternalProvider },
        loadComponent: () =>
          import('@app/pages/redirectionpage/redirectionpage.component').then((m) => m.RedirectionpageComponent),
      },
    ],
  },
  {
    path: 'help',
    loadComponent: () => import('./pages/notfoundpage/notfoundpage.component').then((m) => m.NotfoundpageComponent),
    resolve: { profile: NotfoundResolver },
  },
  {
    path: '',
    loadChildren: () =>
      import('@modules/business-dispatcher/business-dispatcher-routing.module').then((m) => m.ClientRoutingModule),
  },
  {
    path: '**',
    loadComponent: () => import('./pages/notfoundpage/notfoundpage.component').then((m) => m.NotfoundpageComponent),
    resolve: { profile: NotfoundResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
