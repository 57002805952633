import { Rights } from '@core/roles/rights.enum';

export enum Groups {
  ALL = 'all',
  super_admin = 'super_admin',

  admin = 'admin',
  admin_mp = 'admin_mp',
  admin_div = 'admin_div',

  gest_circuit = 'gest_circuit',
  gest_circuit_mp = 'gest_circuit_mp',
  gest_circuit_div = 'gest_circuit_div',

  gest_circuit_junior = 'gest_circuit_junior',
  gest_circuit_junior_mp = 'gest_circuit_junior_mp',
  gest_circuit_junior_div = 'gest_circuit_junior_div',

  gest_modele = 'gest_modele',
  gest_modele_mp = 'gest_modele_mp',
  gest_modele_div = 'gest_modele_div',

  resp_metier = 'resp_metier',
  resp_metier_mp = 'resp_metier_mp',
  resp_metier_div = 'resp_metier_div',

  resp_suivi = 'resp_suivi',
  resp_suivi_mp = 'resp_suivi_mp',
  resp_suivi_div = 'resp_suivi_div',

  virtual_user = 'virtual_user',
  contact = 'contact',

  destinataire = 'destinataire',
  destinataire_mp = 'destinataire_mp',
  destinataire_div = 'destinataire_div',
}

export enum TypeGroups {
  profile = 'profile',
  division = 'division',
  marketplace = 'marketplace',
}

export const divisionRights = [
  Groups.admin_div,
  Groups.gest_circuit_div,
  Groups.destinataire_div,
  Groups.gest_modele_div,
  Groups.resp_metier_div,
  Groups.resp_suivi_div,
  Groups.gest_circuit_junior_div,
];

export const marketplaceRights = [
  Groups.admin_mp,
  Groups.gest_circuit_mp,
  Groups.destinataire_mp,
  Groups.gest_modele_mp,
  Groups.resp_metier_mp,
  Groups.resp_suivi_mp,
  Groups.gest_circuit_junior_mp,
];

export const entityRights = [
  Groups.admin,
  Groups.gest_circuit,
  Groups.destinataire,
  Groups.gest_modele,
  Groups.resp_metier,
  Groups.resp_suivi,
  Groups.gest_circuit_junior,
];

export const workflowRights = [
  Groups.admin,
  Groups.admin_mp,
  Groups.admin_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.resp_suivi,
  Groups.resp_suivi_mp,
  Groups.resp_suivi_div,

  Groups.destinataire,
  Groups.destinataire_mp,
  Groups.destinataire_div,
];

export const statisticsRights = [
  Groups.admin,
  Groups.admin_mp,
  Groups.admin_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.resp_suivi,
  Groups.resp_suivi_mp,
  Groups.resp_suivi_div,
];

export const createWorkflowRights = [
  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,
];
export const GroupsWithCreateCampaignRights = [Groups.resp_metier, Groups.resp_metier_mp, Groups.resp_metier_div];

export const createWorkflowRightsComplete = [
  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,
];

export const createTemplateRights = [
  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,
];

export const updateTemplateRights = [
  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,
];

export const duplicateTemplateRights = [
  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,
];

export const deleteTemplateRights = [
  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,
];

export const useTemplateRights = [
  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,
];

export const exportCSVTemplateRights = [
  Groups.admin,
  Groups.admin_mp,
  Groups.admin_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,
];

export const createBatchWorkflows = [Groups.resp_metier, Groups.resp_metier_mp, Groups.resp_metier_div];

export const templateRights = [
  Groups.admin,
  Groups.admin_mp,
  Groups.admin_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.resp_suivi,
  Groups.resp_suivi_mp,
  Groups.resp_suivi_div,
];
export const profilRights = [
  Groups.admin,
  Groups.admin_mp,
  Groups.admin_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.resp_suivi,
  Groups.resp_suivi_mp,
  Groups.resp_suivi_div,

  Groups.destinataire,
  Groups.destinataire_mp,
  Groups.destinataire_div,
];

export const administrationRights = [Groups.admin, Groups.admin_mp, Groups.admin_div];

export const contactsRights = [
  Groups.admin,
  Groups.admin_mp,
  Groups.admin_div,

  Groups.gest_circuit,
  Groups.gest_circuit_mp,
  Groups.gest_circuit_div,

  Groups.gest_circuit_junior,
  Groups.gest_circuit_junior_mp,
  Groups.gest_circuit_junior_div,

  Groups.resp_metier,
  Groups.resp_metier_mp,
  Groups.resp_metier_div,

  Groups.gest_modele,
  Groups.gest_modele_mp,
  Groups.gest_modele_div,
];

export const RightsOfGroups: Map<Groups, any[]> = new Map([
  [Groups.super_admin, [Rights.HOME, Rights.CLIENT, Rights.ENTITY_CREATE]],
  [
    Groups.admin,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.CIRCUIT_TOGGLE,
      Rights.MODEL_VIEW,
      Rights.USER_VIEW,
      Rights.USER_WRITE,
      Rights.USER_DELETE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.MANAGEMENT,
      Rights.REPORT_VIEW,
      Rights.CONSO_VIEW,
      Rights.CONSO_PARAM,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DIVISION_READ,
      Rights.DIVISION_WRITE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.ETAPE_PARAMETRE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.admin_mp,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.CIRCUIT_TOGGLE,
      Rights.MODEL_VIEW,
      Rights.USER_VIEW,
      Rights.USER_WRITE,
      Rights.USER_DELETE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.MANAGEMENT,
      Rights.REPORT_VIEW,
      Rights.CONSO_VIEW,
      Rights.CONSO_PARAM,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DIVISION_READ,
      Rights.DIVISION_WRITE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.ETAPE_PARAMETRE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.admin_div,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.CIRCUIT_TOGGLE,
      Rights.MODEL_VIEW,
      Rights.USER_VIEW,
      Rights.USER_WRITE,
      Rights.USER_DELETE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.MANAGEMENT,
      Rights.REPORT_VIEW,
      Rights.CONSO_VIEW,
      Rights.CONSO_PARAM,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DIVISION_READ,
      Rights.DIVISION_WRITE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.ETAPE_PARAMETRE,
      Rights.EXPORT,
    ],
  ],

  [
    Groups.gest_circuit,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_UPDATE,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_DELETE,
      Rights.MODEL_VIEW,
      Rights.MODEL_USE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.ETAPE_PARAMETRE,
      Rights.CIRCUIT_DUPLICATE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_circuit_mp,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_UPDATE,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_DELETE,
      Rights.MODEL_VIEW,
      Rights.MODEL_USE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.ETAPE_PARAMETRE,
      Rights.CIRCUIT_DUPLICATE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_circuit_div,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_UPDATE,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_DELETE,
      Rights.MODEL_VIEW,
      Rights.MODEL_USE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.ETAPE_PARAMETRE,
      Rights.CIRCUIT_DUPLICATE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_circuit_junior,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_DELETE,
      Rights.CIRCUIT_RESEND,
      Rights.MODEL_VIEW,
      Rights.MODEL_USE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_circuit_junior_mp,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.MODEL_VIEW,
      Rights.MODEL_USE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_circuit_junior_div,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_DELETE,
      Rights.CIRCUIT_CREATE,
      Rights.MODEL_VIEW,
      Rights.MODEL_USE,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_modele,
    [
      Rights.MODEL_VIEW,
      Rights.MODEL_CREATE,
      Rights.MODEL_DELETE,
      Rights.MODEL_DUPLICATE,
      Rights.MODEL_BLOCK,
      Rights.NOT_BACK,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_modele_mp,
    [
      Rights.MODEL_VIEW,
      Rights.MODEL_CREATE,
      Rights.MODEL_DELETE,
      Rights.MODEL_DUPLICATE,
      Rights.MODEL_BLOCK,
      Rights.NOT_BACK,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.gest_modele_div,
    [
      Rights.MODEL_VIEW,
      Rights.MODEL_CREATE,
      Rights.MODEL_DELETE,
      Rights.MODEL_DUPLICATE,
      Rights.MODEL_BLOCK,
      Rights.NOT_BACK,
      Rights.CONTACT_VIEW,
      Rights.CONTACT_WRITE,
      Rights.CONTACT_DELETE,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.resp_suivi,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.MODEL_VIEW,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.resp_suivi_mp,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.MODEL_VIEW,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.resp_suivi_div,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_RESEND,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.MODEL_VIEW,
      Rights.HOME,
      Rights.CONSO_VIEW,
      Rights.REPORT_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.EXPORT,
    ],
  ],

  [
    Groups.resp_metier,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_DELETE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.MODEL_VIEW,
      Rights.MODEL_CREATE,
      Rights.MODEL_DELETE,
      Rights.MODEL_USE,
      Rights.MODEL_DUPLICATE,
      Rights.MODEL_BLOCK,
      Rights.HOME,
      Rights.CONTACT_WRITE,
      Rights.REPORT_VIEW,
      Rights.CONSO_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.CONTACT_VIEW,
      Rights.CIRCUIT_DUPLICATE,
      Rights.CIRCUIT_MASSE_MODEL,
      Rights.CIRCUIT_UPDATE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.resp_metier_mp,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_DELETE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.MODEL_VIEW,
      Rights.MODEL_CREATE,
      Rights.MODEL_DELETE,
      Rights.MODEL_USE,
      Rights.MODEL_DUPLICATE,
      Rights.MODEL_BLOCK,
      Rights.HOME,
      Rights.CONTACT_WRITE,
      Rights.REPORT_VIEW,
      Rights.CONSO_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.CONTACT_VIEW,
      Rights.CIRCUIT_DUPLICATE,
      Rights.CIRCUIT_MASSE_MODEL,
      Rights.CIRCUIT_UPDATE,
      Rights.EXPORT,
    ],
  ],
  [
    Groups.resp_metier_div,
    [
      Rights.CIRCUIT_VIEW,
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.CIRCUIT_TOGGLE,
      Rights.CIRCUIT_DELETE,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.DOWNLOAD_SUPPORTING_DOCUMENTS,
      Rights.CIRCUIT_CREATE,
      Rights.CIRCUIT_CANCEL,
      Rights.CIRCUIT_RESEND,
      Rights.MODEL_VIEW,
      Rights.MODEL_CREATE,
      Rights.MODEL_DELETE,
      Rights.MODEL_USE,
      Rights.MODEL_DUPLICATE,
      Rights.MODEL_BLOCK,
      Rights.HOME,
      Rights.CONTACT_WRITE,
      Rights.REPORT_VIEW,
      Rights.CONSO_VIEW,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.ETAPE_PARAMETRE,
      Rights.CONTACT_VIEW,
      Rights.CIRCUIT_DUPLICATE,
      Rights.CIRCUIT_MASSE_MODEL,
      Rights.CIRCUIT_UPDATE,
      Rights.EXPORT,
    ],
  ],

  [Groups.virtual_user, [Rights.ANONYMOS, Rights.NOT_BACK]],
  [Groups.contact, [Rights.HELP_VIEW]],
  [
    Groups.destinataire,
    [
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.HOME,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.ETAPE_PARAMETRE,
    ],
  ],
  [
    Groups.destinataire_mp,
    [
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.HOME,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.ETAPE_PARAMETRE,
    ],
  ],
  [
    Groups.destinataire_div,
    [
      Rights.SIGNATURES,
      Rights.CIRCUIT_VIEW_ALL,
      Rights.HOME,
      Rights.HELP_VIEW,
      Rights.PREFERENCE_VIEW,
      Rights.DOWNLOAD_EVIDENCE_FILE,
      Rights.DOWNLOAD_EVIDENCE_FOLDER,
      Rights.ETAPE_PARAMETRE,
    ],
  ],
]);
